import React from "react";
import {
    NamedLink,
    SkeletonLoader,
    ResponsiveImage,
} from '../../components';
import { stringFromLength } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionHero.module.css';
import '../../styles/autocomplete-theme-classic.module.css';

const SectionSubcategories = props => {
    const {
        categories,
        subCategories,
        categoriesInProgress
    } = props;

    return (
        <div className={css.heroCategoriesBox}>
            {!categoriesInProgress ? (
                Array.isArray(subCategories) && subCategories
                    .sort((a, b) => ('' + a.label).localeCompare(b.label))
                    .map((item, index) => {
                        const imageURL = Object.hasOwn(item, 'image') ? item.image : null;
                        const updatedURL = imageURL !== null ? imageURL?.split('upload/')?.join('upload/w_72,h_72/f_auto/q_auto/') : null;
                        const parentIdLabel = Array.isArray(categories) && categories.length ? categories.find(cat => cat.id == item?.parentId)?.label : "all";

                        // item && item.parentId && categories[item.parentId] && categories[item.parentId].name

                        return parentIdLabel ? (
                            <NamedLink
                                name={'AlgoliaSearchPage'}
                                key={item.key}
                                className={css.heroCategories}
                                to={{
                                    // categoriesLabel: categories[item?.parentId]?.label,
                                    categoriesLabel: parentIdLabel,
                                    subCategoriesLabel: item.key,
                                }}
                            >
                                {item.image ? (
                                    <div className={css.categoriesImage}>
                                        <ResponsiveImage
                                            // url={item.image}
                                            url={updatedURL}
                                            className={css.logoBrand}
                                            alt={item.label}
                                        />
                                    </div>
                                ) : (
                                    <div className={css.noImage}>
                                        <FormattedMessage id="SectionHero.noImageText" />
                                    </div>
                                )}
                                <div className={css.categoriesText}>
                                    <h4>{item.label}</h4>
                                    <p>
                                        {item.shortDescription1
                                            ? stringFromLength(item.shortDescription1, 75, true)
                                            : ''}
                                    </p>
                                </div>
                            </NamedLink>
                        ) : null;
                    })
            ) : (
                <SkeletonLoader />
            )}
        </div>
    )
};

export default SectionSubcategories;